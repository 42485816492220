import React from 'react';
import { useIntl } from '@itsa.io/web3utils';
import useStyles from 'styles/pages/TransferAgents';

const TransferAgentsPage = () => {
	const { t } = useIntl();
	const classes = useStyles();

	return (
		<div className={classes.root}>{t('page.transfer_agent.description')}</div>
	);
};

export default TransferAgentsPage;
